var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Section',{staticClass:"mb-4",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',[_vm._v(" Address Information "),(_vm.addressData.isMonitoring)?_c('MonitoringButton'):_vm._e()],1)]},proxy:true},{key:"body",fn:function(){return [(!_vm.hasTxs && !_vm.addressDataLoading)?_c('div',{staticClass:"wallet-address-wrap pa-2"},[_vm._v(" There are no transactions for this address ")]):_vm._e(),_c('div',{staticClass:"pa-2"},[_c('InfoBlock',{staticClass:"mb-4",attrs:{"flex-second-column-two":"","inline-view":"","label":"Wallet address","loading":_vm.addressDataLoading,"value":("" + (_vm.addressData && _vm.addressData.address || _vm.address))}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"flex-second-column-two":"","inline-view":"","label":"Type of the address","loading":_vm.addressDataLoading,"value":"Externally-owned Account (EOA)"}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"flex-second-column-two":"","inline-view":"","label":"Token Balance","loading":_vm.addressDataLoading,"value":_vm.addressData
            && _vm.addressData.balance
            && ((_vm.toComaSeparate(String(_vm.restrictNumberAfterComma(_vm.addressData.balance.amount, 8)))) + " " + (_vm.selectedToken.symbol))}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"flex-second-column-two":"","inline-view":"","label":"Number of TXS","loading":_vm.addressDataLoading,"value":_vm.addressData
            && _vm.toComaSeparate(String(_vm.addressData.txCount)) || '0'}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"flex-second-column-two":"","inline-view":"","label":"Total tx sent","loading":_vm.addressDataLoading,"value":_vm.addressData
            && _vm.addressData.txOutputsCount
            && _vm.toComaSeparate(String(_vm.addressData.txOutputsCount)) || '0'}}),_c('InfoBlock',{attrs:{"flex-second-column-two":"","inline-view":"","label":"Total tx received","loading":_vm.addressDataLoading,"value":_vm.addressData
            && _vm.addressData.txInputsCount
            && _vm.toComaSeparate(String(_vm.addressData.txInputsCount)) || '0'}})],1)]},proxy:true}])}),_c('Section',{staticClass:"mb-4",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',[_vm._v(" Owner Information ")])]},proxy:true},{key:"body",fn:function(){return [(_vm.isOwnerExist(_vm.addressData))?_c('ReportSectionBody',{attrs:{"data":_vm.addressData,"data-description":_vm.addressData.description,"label-tag":"Extra context tags towards the classification of the requested address","label-top":"Owner / ENS","label-type":"Type of entity that owns requested address","owner-name":((_vm.addressData && _vm.addressData.owner) + " / " + (_vm.addressData.ens || '--')),"tag-meta-data":_vm.addressData.meta,"type-data":_vm.addressData.type}}):_c('div',{staticClass:"pa-2"},[_vm._v(" Not identified ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }